<template>
  <div id="product">
    <el-dialog
      :title="airFormTitle"
      width="1200px"
      :visible.sync="airDialogVisible"
      :close-on-click-modal="false"
      @close="airDialogClose"
      ><el-form
        ref="airFormRef"
        :model="airForm"
        :rules="productFormRules2"
        label-position="right"
        label-width="250px"
      >
        <el-col :span="24">
          <el-form-item label="设备名称" prop="deviceId">
          <el-select
            v-model="airForm.deviceId"
            placeholder="请选择设备"
            clearable
            filterable
            :disabled="handleType !== 'add' && handleType !== 'update'"
          >
            <el-option v-for="item in deviceList" :key="item.id" :label="item.deviceName" :value="item.id" />
          </el-select>
        </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="原水供应、电源等是否正常" prop="checkItemOne">
            <el-radio-group
              v-model="airForm.checkItemOne"
              :disabled="handleType !== 'add' && handleType !== 'update'"
            >
              <el-radio label="1"> 正常 </el-radio>
              <el-radio label="2"> 不正常 </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item
            label="多介质过滤器是否在在运行位置上"
            prop="checkItemTwo"
          >
            <el-radio-group
              v-model="airForm.checkItemTwo"
              :disabled="handleType !== 'add' && handleType !== 'update'"
            >
              <el-radio label="1"> 是 </el-radio>
              <el-radio label="2"> 否 </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="管路接头，阀门等是否正常" prop="checkItemThree">
            <el-radio-group
              v-model="airForm.checkItemThree"
              :disabled="handleType !== 'add' && handleType !== 'update'"
            >
              <el-radio label="1"> 是 </el-radio>
              <el-radio label="2"> 否 </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="加药系统药液是否充足" prop="checkItemFour">
            <el-radio-group
              v-model="airForm.checkItemFour"
              :disabled="handleType !== 'add' && handleType !== 'update'"
            >
              <el-radio label="1"> 是 </el-radio>
              <el-radio label="2"> 否 </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="是否做全排放" prop="waterUse">
            <el-radio-group
              v-model="airForm.waterUse"
              :disabled="handleType !== 'add' && handleType !== 'update'"
            >
              <el-radio label="1"> 是 </el-radio>
              <el-radio label="2"> 否 </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="排放时间" prop="dischargeTime">
            <el-date-picker
              v-model="airForm.dischargeTime"
              type="datetime"
              placeholder="排放时间"
              value-format="yyyy-MM-dd hh:mm:ss"
              clearable
              :readonly="handleType !== 'add' && handleType !== 'update'"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="制水时间" prop="productionEndTime">
            <el-date-picker
              v-model="runningTime"
              :readonly="handleType !== 'add' && handleType !== 'update'"
              type="datetimerange"
              value-format="yyyy-MM-dd hh:mm:ss"
              range-separator="至"
              start-placeholder="制水开始时间"
              end-placeholder="制水结束时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="handleType !== 'detail'">
          <el-form-item label="紫外灯安装启用日期" prop="uvLampTime">
            <el-date-picker
              v-model="airForm.uvLampTime"
              type="datetime"
              placeholder="紫外灯安装启用日期"
              value-format="yyyy-MM-dd hh:mm:ss"
              clearable
              :readonly="handleType !== 'add' && handleType !== 'update'"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="有效期" prop="validPeriod">
            <el-date-picker
              v-model="airForm.validPeriod"
              type="datetime"
              placeholder="有效期"
              value-format="yyyy-MM-dd hh:mm:ss"
              clearable
              :readonly="handleType !== 'add' && handleType !== 'update'"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="handleType !== 'detail'">
          <el-form-item label="备注" prop="remarks">
            <el-input
              v-model="airForm.remarks"
              placeholder="请输入备注"
              type="textarea"
              clearable
              :readonly="handleType !== 'add' && handleType !== 'update'"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="handleType !== 'add'">
          <el-form-item label="检查人">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="small"
              v-if="!airForm.checker"
              @click.stop="handleCheck(airForm.id)"
            >
              检查
            </el-button>
            <p class="el-input__inner" style="margin:0;" v-else>
                <span>{{airForm.checker}}</span>
            </p>
          </el-form-item>
        </el-col>
      </el-form>
      <div slot="footer">
        <el-button @click="airDialogVisible = false"> 取 消 </el-button>
        <el-button type="primary" @click="productFormSubmit"> 确 定 </el-button>
      </div>
      <section v-if="handleType != 'add'">
        <el-button
          type="primary"
          @click="handleAdd2"
          v-if="handleType === 'update'"
        >
          新 增
        </el-button>
        <el-table
          :data="airForm.recordList"
          style="width: 100%"
          :span-method="objectSpanMethod"
        >
          <el-table-column>
            <p>纯化水设备记录</p>
          </el-table-column>
          <el-table-column label="记录时间" align="center">
            <el-table-column label="时" align="center">
              <template slot-scope="scope">
                <div v-if="scope.$index + 2 < airForm.recordList.length">
                  {{ scope.row.recordTime.split(" ")[1].split(":")[0] }}
                </div>
                <div v-if="(scope.$index + 2) === airForm.recordList.length" style="text-align: left">
                  紫外灯安装启用日期：{{ airForm.uvLampTime }} 有效期至：{{
                    airForm.validPeriod
                  }}
                </div>
                <div v-if="(scope.$index + 1) === airForm.recordList.length" style="text-align: left">
                  备注：{{ airForm.remarks }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="分" align="center">
              <template slot-scope="scope">
                <div v-if="scope.$index + 2 < airForm.recordList.length">
                  {{ scope.row.recordTime.split(" ")[1].split(":")[1] }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="反渗透系" align="center">
            <el-table-column
              prop="outletPressure"
              label="原水泵出水压力"
              align="center"
            />
            <el-table-column
              prop="filterPressure"
              label="多介质过滤器后压MPa"
              align="center"
            />
            <el-table-column
              prop="securityFilterPressure"
              label="保安过滤器压力"
              align="center"
            />
            <el-table-column label="一级RO膜" align="center">
              <el-table-column
                prop="oneLevelInletPressure"
                label="进水压力MPa"
                align="center"
              />
              <el-table-column
                prop="oneLevelOutletPressure"
                label="出水压力MPa"
                align="center"
              />
            </el-table-column>
            <el-table-column label="二级RO膜" align="center">
              <el-table-column
                prop="twoLevelInletPressure"
                label="进水压力MPa"
                align="center"
              />
              <el-table-column
                prop="twoLevelOutletPressure"
                label="出水压力MPa"
                align="center"
              />
            </el-table-column>
            <el-table-column label="浓流水量" align="center">
              <el-table-column
                prop="oneThickWaterFlow"
                label="一级LPM"
                align="center"
              />
              <el-table-column
                prop="twoThickWaterFlow"
                label="二级LPM"
                align="center"
              />
            </el-table-column>
            <el-table-column label="淡流水量" align="center">
              <el-table-column
                prop="oneFreshWaterFlow"
                label="一级LPM"
                align="center"
              />
              <el-table-column
                prop="twoFreshWaterFlow"
                label="二级LPM"
                align="center"
              />
            </el-table-column>
            <el-table-column label="电导率" align="center">
              <el-table-column
                prop="primaryConductivity"
                label="一级μs/cm"
                align="center"
              />
              <el-table-column
                prop="secondaryConductivity"
                label="二级μs/cm"
                align="center"
              />
            </el-table-column>
          </el-table-column>
          <el-table-column
            prop="operator"
            label="系统操作人"
            align="center"
          >
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                icon="el-icon-delete"
                size="small"
                @click.stop="
                  handleDelete2(scope.$index, scope.row, airForm.recordList)
                "
                v-if="handleType === 'update'"
              >
                删除
              </el-button>
              <el-button
                type="text"
                icon="el-icon-edit"
                size="small"
                @click.stop="handleUpdate2(scope.$index, scope.row)"
                v-if="handleType === 'update'"
              >
                修改
              </el-button>
              <el-button
                type="text"
                icon="el-icon-info"
                size="small"
                @click.stop="handleInfo2(scope.$index, scope.row)"
              >
                详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-table
          :data="[airForm]"
          style="width: 100%"
          :show-header="false"
          :span-method="objectSpanMethod2"
        >
          <el-table-column>
            <p>
              每制水班次在设备调试合格后，每隔15-30分钟检查记录各仪表参数1次：一级反渗透出水电导率应≤20μs/cm，二级反渗透出水电导率应≤2.0μs/m
            </p>
          </el-table-column>
          <el-table-column align="center"> </el-table-column>
          <el-table-column align="center"> </el-table-column>
          <el-table-column align="center"> </el-table-column>
          <el-table-column align="center"> </el-table-column>
          <el-table-column align="center"> </el-table-column>
        </el-table>
        <!-- <div slot="footer">
          <el-button @click="airDialogVisible = false">关闭</el-button>
        </div> -->
      </section>
      <el-dialog
        width="60%"
        title="新增操作记录"
        :visible.sync="innerVisible"
        :close-on-click-modal="false"
        append-to-body
      >
        <el-form
          ref="productFormRef"
          :model="airDetailForm"
          :rules="productFormRules"
          label-position="right"
          label-width="200px"
        >
          <el-form-item label="记录时间" prop="recordTime">
            <el-date-picker
              v-model="airDetailForm.recordTime"
              type="datetime"
              placeholder="记录时间"
              value-format="yyyy-MM-dd hh:mm:ss"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="原水泵出水压力（MPa）" prop="outletPressure">
            <el-input
              v-model="airDetailForm.outletPressure"
              placeholder="请输入原水泵出水压力（MPa）"
              type="text"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item label="多介质过滤器后压（MPa）" prop="filterPressure">
            <el-input
              v-model="airDetailForm.filterPressure"
              placeholder="请输入多介质过滤器后压（MPa）"
              type="text"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item
            label="保安过滤器压力（MPa）"
            prop="securityFilterPressure"
          >
            <el-input
              v-model="airDetailForm.securityFilterPressure"
              placeholder="请输入保安过滤器压力（MPa）"
              type="text"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item
            label="一级进水压力（MPa）"
            prop="oneLevelInletPressure"
          >
            <el-input
              v-model="airDetailForm.oneLevelInletPressure"
              placeholder="请输入一级进水压力（MPa）"
              type="text"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item
            label="一级出水压力（MPa）"
            prop="oneLevelOutletPressure"
          >
            <el-input
              v-model="airDetailForm.oneLevelOutletPressure"
              placeholder="请输入一级出水压力（MPa）"
              type="text"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item
            label="二级进水压力（MPa）"
            prop="twoLevelInletPressure"
          >
            <el-input
              v-model="airDetailForm.twoLevelInletPressure"
              placeholder="请输入二级进水压力（MPa）"
              type="text"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item
            label="二级出水压力（MPa）"
            prop="twoLevelOutletPressure"
          >
            <el-input
              v-model="airDetailForm.twoLevelOutletPressure"
              placeholder="请输入二级出水压力（MPa）"
              type="text"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item label="一级浓水流量（LPM）" prop="oneThickWaterFlow">
            <el-input
              v-model="airDetailForm.oneThickWaterFlow"
              placeholder="请输入一级浓水流量（LPM）"
              type="text"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item label="二级浓水流量（LPM）" prop="twoThickWaterFlow">
            <el-input
              v-model="airDetailForm.twoThickWaterFlow"
              placeholder="请输入二级浓水流量（LPM）"
              type="text"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item label="一级淡水流量（LPM）" prop="oneFreshWaterFlow">
            <el-input
              v-model="airDetailForm.oneFreshWaterFlow"
              placeholder="请输入一级淡水流量（LPM）"
              type="text"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item label="二级淡水流量（LPM）" prop="twoFreshWaterFlow">
            <el-input
              v-model="airDetailForm.twoFreshWaterFlow"
              placeholder="请输入二级淡水流量（LPM）"
              type="text"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item label="一级导电率（μs/cm）" prop="primaryConductivity">
            <el-input
              v-model="airDetailForm.primaryConductivity"
              placeholder="请输入一级导电率（μs/cm）"
              type="text"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item
            label="二级导电率（μs/cm）"
            prop="secondaryConductivity"
          >
            <el-input
              v-model="airDetailForm.secondaryConductivity"
              placeholder="请输入二级导电率（μs/cm）"
              type="text"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="innerVisible = false"> 取 消 </el-button>
          <el-button type="primary" @click="productFormSubmit2">
            确 定
          </el-button>
        </div>
      </el-dialog>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="检查人">
        <el-input
          v-model="searchForm.checker"
          placeholder="请输入检查人"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="airList.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
    >
      <el-table-column prop="dischargeTime" label="排放时间"></el-table-column>
      <el-table-column prop="uvLampTime" label="紫外灯安装启用日期" />
      <el-table-column prop="productionStartTime" label="制水开始时间" />
      <el-table-column prop="productionEndTime" label="制水结束时间" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="airList.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  getWaterPreparationList,
  deleteWaterPreparation,
  deleteWaterPreparationDetail,
  addWaterPreparationList,
  addWaterPreparationInfoDetail,
  getWaterPreparationDetail,
  updateWaterPreparationDetail,
  checkWaterPreparationList,
} from "@/api/produce/produce.js";
import { selectBaseInfoList } from "@/api/device/baseInfo";

export default {
  data() {
    return {
      airDialogVisible: false,
      innerVisible: false,
      airFormTitle: "",
      airForm: {},
      productPage: {
        list: [],
        total: 0,
      },
      airList: {
        list: [],
        total: 0,
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        checker: "",
      },
      typeList: [],
      typeMap: [],
      tableData: [],
      handleType: "",
      handleDetailType: "",
      runningTime: [],
      airDetailForm: {},
      productFormRules: {
        recordTime: [
          {
            required: true,
            message: "记录时间不能为空",
            trigger: ["blur"],
          },
        ],
        outletPressure: [
          {
            required: true,
            message: "原水泵出水压力不能为空",
            trigger: ["blur"],
          },
        ],
        filterPressure: [
          {
            required: true,
            message: "多介质过滤器后压不能为空",
            trigger: ["blur"],
          },
        ],
        securityFilterPressure: [
          {
            required: true,
            message: "保安过滤器压力不能为空",
            trigger: ["blur"],
          },
        ],
        oneLevelInletPressure: [
          {
            required: true,
            message: "一级进水压力不能为空",
            trigger: ["blur"],
          },
        ],
        oneLevelOutletPressure: [
          {
            required: true,
            message: "一级出水压力不能为空",
            trigger: ["blur"],
          },
        ],
        twoLevelInletPressure: [
          {
            required: true,
            message: "二级进水压力不能为空",
            trigger: ["blur"],
          },
        ],
        twoLevelOutletPressure: [
          {
            required: true,
            message: "二级出水压力不能为空",
            trigger: ["blur"],
          },
        ],
        oneThickWaterFlow: [
          {
            required: true,
            message: "一级浓水流量不能为空",
            trigger: ["blur"],
          },
        ],
        twoThickWaterFlow: [
          {
            required: true,
            message: "二级浓水流量不能为空",
            trigger: ["blur"],
          },
        ],
        oneFreshWaterFlow: [
          {
            required: true,
            message: "一级淡水流量不能为空",
            trigger: ["blur"],
          },
        ],
        twoFreshWaterFlow: [
          {
            required: true,
            message: "二级淡水流量不能为空",
            trigger: ["blur"],
          },
        ],
        primaryConductivity: [
          {
            required: true,
            message: "一级导电率不能为空",
            trigger: ["blur"],
          },
        ],
        secondaryConductivity: [
          {
            required: true,
            message: "二级导电率不能为空",
            trigger: ["blur"],
          },
        ],
      },
      productFormRules2: {
        deviceId: [
          {
            required: true,
            message: "设备名称不能为空",
            trigger: ["blur"],
          },
        ],
        checkItemOne: [
          {
            required: true,
            message: "原水供应、电源等是否正常不能为空",
            trigger: ["blur"],
          },
        ],
        checkItemTwo: [
          {
            required: true,
            message: "多介质过滤器是否在在运行位置上不能为空",
            trigger: ["blur"],
          },
        ],
        checkItemThree: [
          {
            required: true,
            message: "管路接头，阀门等是否正常不能为空",
            trigger: ["blur"],
          },
        ],
        checkItemFour: [
          {
            required: true,
            message: "加药系统药液是否充足不能为空",
            trigger: ["blur"],
          },
        ],
        waterUse: [
          {
            required: true,
            message: "是否做全排放不能为空",
            trigger: ["blur"],
          },
        ],
        dischargeTime: [
          {
            required: true,
            message: "排放时间不能为空",
            trigger: ["blur"],
          },
        ],
        productionEndTime: [
          {
            required: true,
            message: "制水时间不能为空",
            trigger: ["blur"],
          },
        ],
        uvLampTime: [
          {
            required: true,
            message: "紫外灯安装启用日期不能为空",
            trigger: ["blur"],
          },
        ],
        validPeriod: [
          {
            required: true,
            message: "有效期不能为空",
            trigger: ["blur"],
          },
        ],
      },
      count: {
        waterId: "",
        recordTime: "",
        outletPressure: "",
        filterPressure: "",
        securityFilterPressure: "",
        oneLevelInletPressure: "",
        oneLevelOutletPressure: "",
        twoLevelInletPressure: "",
        twoLevelOutletPressure: "",
        oneThickWaterFlow: "",
        twoThickWaterFlow: "",
        oneFreshWaterFlow: "",
        twoFreshWaterFlow: "",
        primaryConductivity: "",
        secondaryConductivity: "",
        operator: "",
        operateTime: "",
      },
      count2: {
        waterId: "",
        recordTime: "",
        outletPressure: "",
        filterPressure: "",
        securityFilterPressure: "",
        oneLevelInletPressure: "",
        oneLevelOutletPressure: "",
        twoLevelInletPressure: "",
        twoLevelOutletPressure: "",
        oneThickWaterFlow: "",
        twoThickWaterFlow: "",
        oneFreshWaterFlow: "",
        twoFreshWaterFlow: "",
        primaryConductivity: "",
        secondaryConductivity: "",
        operator: "",
        operateTime: "",
      },
      deviceList:[]
    };
  },
  async created() {
    let params2 = {
        pageNum: 1,
        pageSize: 999,
      }
    selectBaseInfoList(params2).then((res) => {
      console.log(res,'res')
      this.deviceList = res.list;
    });
    let params = {
      pageNum: 1,
      pageSize: 10,
    };
    let airList = await getWaterPreparationList(params);
    this.airList = airList;
  },
  methods: {
    airDialogClose() {
      this.$refs.airFormRef.resetFields();
    },
    productFormSubmit() {
      this.$refs.airFormRef.validate(async (valid) => {
        if (valid) {
          if (this.handleType === "add") {
            console.log(this.airForm, "this.airForm");
            await addWaterPreparationList(this.airForm);
          } else if (this.handleType === "update") {
            let params = this.airForm;
            params.waterRecordJson = JSON.stringify(
              params.recordList || []
            );
            delete params.recordList;
            console.log(params, "params");
            await updateWaterPreparationDetail(params);
          }
          this.airList = await getWaterPreparationList(this.searchForm);
          this.airDialogVisible = false;
        }
      });
    },
    productFormSubmit2() {
      this.$refs.airFormRef.validate(async (valid) => {
        this.airDetailForm.waterId = this.airForm.id;
        if (valid) {
          if (this.handleDetailType === "add") {
            console.log(this.airDetailForm, "this.airDetailForm");
            let data = await addWaterPreparationInfoDetail(this.airDetailForm);
            console.log(data, "data");
            let list = [...this.airForm.recordList];
            list.splice(list.length - 2,0,data)
            // list.push(data);
            console.log(list,'list')
            this.airForm.recordList = list;
          } else if (this.handleDetailType === "update") {
            let list = this.airForm.recordList;
            console.log(this.airDetailForm, "this.airDetailForm");
            let index = this.airDetailForm.index;
            delete this.airDetailForm.index;
            delete this.airDetailForm.inputType;
            console.log(list, "list");
            console.log(this.airDetailForm, "this.airDetailForm");
            list.splice(index, 1, this.airDetailForm);
            this.airForm.recordList = list;
          }
          this.innerVisible = false;
        }
      });
    },
    handleAdd() {
      this.airFormTitle = "新增纯化水设备操作记录";
      this.handleType = "add";
      this.airDialogVisible = true;
    },
    handleAdd2() {
      this.handleDetailType = "add";
      this.innerVisible = true;
    },
    handleDelete(index, row) {
      this.$confirm("确认删除？", "提示", {
        type: "warning",
      }).then(async () => {
        await deleteWaterPreparation(row.id);
        if (this.productPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--;
        }
        this.airList = await getWaterPreparationList(this.searchForm);
      });
    },
    handleDelete2(index, row, list) {
      this.$confirm("确认删除？", "提示", {
        type: "warning",
      }).then(async () => {
        await deleteWaterPreparationDetail(row.id);
        list.splice(index, 1);
      });
    },
    handleUpdate(index, row) {
      this.airFormTitle = "纯化水设备操作记录";
      this.handleType = "update";
      getWaterPreparationDetail(row.id).then((res) => {
        this.airForm = { ...res };
        this.airForm.recordList.push(this.count);
        this.airForm.recordList.push(this.count2);
        this.runningTime = [
          res.productionStartTime || "",
          res.productionEndTime || "",
        ];
        this.airDialogVisible = true;
      });
    },
    handleInfo(index, row) {
      this.airFormTitle = "纯化水设备操作记录";
      this.handleType = "detail";
      getWaterPreparationDetail(row.id).then((res) => {
        this.airForm = { ...res };
        this.airForm.recordList.push(this.count);
        this.airForm.recordList.push(this.count2);
        this.runningTime = [
          res.productionStartTime || "",
          res.productionEndTime || "",
        ];
        this.airDialogVisible = true;
      });
    },
    handleSearch() {
      this.searchForm.pageNum = 1;
      getWaterPreparationList(this.searchForm).then((res) => {
        console.log(res, "res");
        this.airList = res;
      });
    },
    pageNumChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      getWaterPreparationList(this.searchForm).then((res) => {
        this.airList = res;
      });
    },
    pageSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.pageNum = 1;
      getWaterPreparationList(this.searchForm).then((res) => {
        this.airList = res;
      });
    },
    handleInfo2(index, row) {
      this.handleDetailType = "detail";
      let airDetailForm = this.airDetailForm;
      airDetailForm = { ...row };
      airDetailForm.inputType = "detail";
      this.airDetailForm = airDetailForm;
      this.innerVisible = true;
    },
    handleUpdate2(index, row) {
      this.handleDetailType = "update";
      let airDetailForm = this.airDetailForm;
      airDetailForm = { ...row };
      airDetailForm.index = index;
      airDetailForm.inputType = "update";
      this.airDetailForm = airDetailForm;
      this.innerVisible = true;
    },
    handleCheck(id) {
      this.$confirm("确认检查通过？", "提示", {
        type: "warning",
      }).then(async () => {
        let params = {
          id: id,
        };
        await checkWaterPreparationList(params);
        getWaterPreparationDetail(id).then((res) => {
          this.airForm = { ...res };
          this.airForm.recordList.push(this.count);
          this.airForm.recordList.push(this.count2);
          this.runningTime = [
            res.productionStartTime || "",
            res.productionEndTime || "",
          ];
        });
      });
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = this.flitterData(this.airForm.recordList).one[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
      if ((rowIndex + 1 == this.airForm.recordList.length) || (rowIndex + 2 == this.airForm.recordList.length)) {
        if (columnIndex === 1) {
          return {
            rowspan: 1,
            colspan: 18,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    flitterData(arr) {
      let spanOneArr = [],
        concatOne = 0;
      arr.forEach((item, index) => {
        if (index === 0) {
          spanOneArr.push(1);
        } else {
          spanOneArr[concatOne] += 1;
          spanOneArr.push(0);
          //   if (item.operator === arr[index - 1].operator) {
          //     //第一列需合并相同内容的判断条件
          //     spanOneArr[concatOne] += 1;
          //     spanOneArr.push(0);
          //   } else {
          //     spanOneArr.push(1);
          //     concatOne = index;
          //   }
        }
      });
      return {
        one: spanOneArr,
      };
    },
    objectSpanMethod2({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return {
          rowspan: 1,
          colspan: 17,
        };
      }
    },
  },

  watch: {
    runningTime(val, oldVal) {
      this.airForm.productionStartTime = val ? val[0] : "";
      this.airForm.productionEndTime = val ? val[1] : "";
    },
    airDialogVisible(val){
      if(!val){
        this.airForm = {}
        this.runningTime = []
      }
    },
    innerVisible(val){
      if(!val){
        this.airDetailForm = {}
      }
    },
  },
};
</script>

<style>
#product section {
  margin: 0px 0px 20px 20px;
}
</style>
