import axios from 'axios';
import qs from 'qs';

//获取 净化空调运行操作记录列表
export const getAirList = (params, config) => {
    console.log(params, 'params')
    return axios.get(`/production/air/list`, {
        params: {
            ...params
        }
    }, config).then(res => res)
}
//删除 净化空调运行操作记录列表
export const deleteAirList = (params, config) => {
    return axios.delete(`/production/air/delete/${params}`, config).then(res => res)
}
//新增 净化空调运行操作记录列表
export const addAirList = (params, config) => {
    return axios.post(`/production/air/add`, qs.stringify(params), config).then(res => res)
}
//新增 净化空调运行操作详情记录
export const addDetail = (params, config) => {
    return axios.post(`/production/air/addDetail`, qs.stringify(params), config).then(res => res)
}
//获取 净化空调运行操作详情记录
export const getInfoDetail = (params, config) => {
    return axios.get(`/production/air/info/${params}`, config)
}
//修改 净化空调运行操作详情记录
export const updateDetail = (params, config) => {
    return axios.put(`/production/air/update`, qs.stringify(params), config).then(res => res)
}


//获取 水系统消毒记录列表
export const getWaterList = (params, config) => {
    console.log(params, 'params')
    return axios.get(`/production/water/list`, {
        params: {
            ...params
        }
    }, config).then(res => res)
}
//删除 水系统消毒记录列表
export const deleteWaterList = (params, config) => {
    return axios.delete(`/production/water/delete/${params}`, config).then(res => res)
}
//确认 水系统消毒记录列表
export const confirmWaterList = (params, config) => {
    return axios.put(`/production/water/confirm`, qs.stringify(params), config).then(res => res)
}
//新增 水系统消毒记录列表
export const addWaterDetail = (params, config) => {
    return axios.post(`/production/water/add`, qs.stringify(params), config).then(res => res)
}
//获取 水系统消毒记录列表
export const getWaterInfoDetail = (params, config) => {
    return axios.get(`/production/water/info/${params}`, config)
}
//修改 水消毒记录
export const updateWaterDetail = (params, config) => {
    return axios.put(`/production/water/update`, qs.stringify(params), config).then(res => res)
}


//获取 外来人员登记表
export const getOutsiderList = (params, config) => {
    console.log(params, 'params')
    return axios.get(`/production/outsider/list`, {
        params: {
            ...params
        }
    }, config).then(res => res)
}
//删除 外来人员登记表
export const deleteOutsiderList = (params, config) => {
    return axios.delete(`/production/outsider/delete/${params}`, config).then(res => res)
}
//新增 外来人员登记表
export const addOutsiderDetail = (params, config) => {
    return axios.post(`/production/outsider/add`, qs.stringify(params), config).then(res => res)
}
//获取 外来人员登记表
export const getOutsiderInfoDetail = (params, config) => {
    return axios.get(`/production/outsider/info/${params}`, config)
}
//修改 外来人员登记表
export const updateOutsiderDetail = (params, config) => {
    return axios.put(`/production/outsider/update`, qs.stringify(params), config).then(res => res)
}


//获取 工作服清洁列表
export const getClothesWashList = (params, config) => {
    console.log(params, 'params')
    return axios.get(`/production/clothesWash/list`, {
        params: {
            ...params
        }
    }, config).then(res => res)
}
//删除 工作服清洁列表
export const deleteClothesWashList = (params, config) => {
    return axios.delete(`/production/clothesWash/delete/${params}`, config).then(res => res)
}
//新增 工作服清洁列表
export const addClothesWashDetail = (params, config) => {
    return axios.post(`/production/clothesWash/add`, qs.stringify(params), config).then(res => res)
}
//获取 工作服清洁列表详情
export const getClothesWashInfoDetail = (params, config) => {
    return axios.get(`/production/clothesWash/info/${params}`, config)
}
//修改 工作服清洁列表
export const updateClothesWashDetail = (params, config) => {
    return axios.put(`/production/clothesWash/update`, qs.stringify(params), config).then(res => res)
}


//获取 工作服领用列表
export const getClothesStatisticsList = (params, config) => {
    console.log(params, 'params')
    return axios.get(`/production/clothesStatistics/list`, {
        params: {
            ...params
        }
    }, config).then(res => res)
}
//删除 工作服领用列表
export const deleteClothesStatisticsList = (params, config) => {
    return axios.delete(`/production/clothesStatistics/delete/${params}`, config).then(res => res)
}
//新增 工作服领用列表
export const addClothesStatisticsDetail = (params, config) => {
    return axios.post(`/production/clothesStatistics/add`, qs.stringify(params), config).then(res => res)
}
//获取 工作服领用列表详情
export const getClothesStatisticsDetail = (params, config) => {
    return axios.get(`/production/clothesStatistics/info/${params}`, config)
}
//修改 工作服领用列表
export const updateClothesStatisticsDetail = (params, config) => {
    return axios.put(`/production/clothesStatistics/update`, qs.stringify(params), config).then(res => res)
}


//获取 自动口罩机检查记录列表
export const getMaskMachineList = (params, config) => {
    console.log(params, 'params')
    return axios.get(`/production/maskMachine/list`, {
        params: {
            ...params
        }
    }, config).then(res => res)
}
//删除 自动口罩机检查记录列表
export const deleteMaskMachineList = (params, config) => {
    return axios.delete(`/production/maskMachine/delete/${params}`, config).then(res => res)
}
//复核 自动口罩机检查记录列表
export const confirmMaskMachineList = (params, config) => {
    return axios.put(`/production/maskMachine/review`, qs.stringify(params), config).then(res => res)
}
//新增 自动口罩机检查记录列表
export const addMaskMachineDetail = (params, config) => {
    return axios.post(`/production/maskMachine/add`, qs.stringify(params), config).then(res => res)
}
//获取 自动口罩机检查记录列表
export const getMaskMachineInfoDetail = (params, config) => {
    return axios.get(`/production/maskMachine/info/${params}`, config)
}
//修改 自动口罩机检查记录
export const updateMaskMachineDetail = (params, config) => {
    return axios.put(`/production/maskMachine/update`, qs.stringify(params), config).then(res => res)
}


//获取 生产记录审核单列表
export const getRecordList = (params, config) => {
    console.log(params, 'params')
    return axios.get(`/production/record/list`, {
        params: {
            ...params
        }
    }, config).then(res => res)
}
//删除 生产记录审核单列表
export const deleteRecordList = (params, config) => {
    return axios.delete(`/production/record/delete/${params}`, config).then(res => res)
}
//质量部审核 生产记录审核单列表
export const qualityReviewRecordList = (params, config) => {
    return axios.post(`/production/record/qualityReview`, qs.stringify(params), config).then(res => res)
}
//车间审核 生产记录审核单列表
export const workshopReviewRecordList = (params, config) => {
    return axios.post(`/production/record/workshopReview`, qs.stringify(params), config).then(res => res)
}
//新增 生产记录审核单列表
export const addRecordList = (params, config) => {
    return axios.post(`/production/record/add`, qs.stringify(params), config).then(res => res)
}
//新增 生产记录审核单详情
export const addRecordInfoDetail = (params, config) => {
    return axios.post(`/production/record/addDetail`, qs.stringify(params), config).then(res => res)
}
//获取 生产记录审核单列表
export const getRecordDetail = (params, config) => {
    return axios.get(`/production/record/info/${params}`, config)
}
//修改 生产记录审核单列表
export const updateRecordDetail = (params, config) => {
    return axios.put(`/production/record/update`, qs.stringify(params), config).then(res => res)
}


//获取 清场工作记录
export const getClearanceList = (params, config) => {
    console.log(params, 'params')
    return axios.get(`/production/clearance/list`, {
        params: {
            ...params
        }
    }, config).then(res => res)
}
//删除 清场工作记录
export const deleteClearanceList = (params, config) => {
    return axios.delete(`/production/clearance/delete/${params}`, config).then(res => res)
}
//新增 清场工作记录
export const addClearanceList = (params, config) => {
    return axios.post(`/production/clearance/add`, qs.stringify(params), config).then(res => res)
}
//新增 清场工作记录详情
export const addClearanceInfoDetail = (params, config) => {
    return axios.post(`/production/clearance/addDetail`, qs.stringify(params), config).then(res => res)
}
//生成 清场工作记录详情
export const generateClearanceList = (params, config) => {
    return axios.post(`/production/clearance/generate`, qs.stringify(params), config).then(res => res)
}
//获取 清场工作记录
export const getClearanceDetail = (params, config) => {
    return axios.get(`/production/clearance/info/${params}`, config)
}
//修改 清场工作记录
export const updateClearanceDetail = (params, config) => {
    return axios.put(`/production/clearance/update`, qs.stringify(params), config).then(res => res)
}
//检查 清场工作记录
export const checkClearanceList = (params, config) => {
    return axios.put(`/production/clearance/check`, qs.stringify(params), config).then(res => res)
}
//复核 清场工作记录
export const reviewClearanceList = (params, config) => {
    return axios.put(`/production/clearance/review`, qs.stringify(params), config).then(res => res)
}


//获取 纯化水设备操作记录
export const getWaterPreparationList = (params, config) => {
    return axios.get(`/production/waterPreparation/list`, {
        params: {
            ...params
        }
    }, config).then(res => res)
}
//删除 纯化水设备操作记录
export const deleteWaterPreparation = (params, config) => {
    return axios.delete(`/production/waterPreparation/delete/${params}`, config).then(res => res)
}
//删除 纯化水设备操作详情记录
export const deleteWaterPreparationDetail = (params, config) => {
    return axios.delete(`/production/waterPreparation/deleteRecord/${params}`, config).then(res => res)
}
//新增 纯化水设备操作记录
export const addWaterPreparationList = (params, config) => {
    return axios.post(`/production/waterPreparation/add`, qs.stringify(params), config).then(res => res)
}
//新增 纯化水设备详情操作记录
export const addWaterPreparationInfoDetail = (params, config) => {
    return axios.post(`/production/waterPreparation/addDetail`, qs.stringify(params), config).then(res => res)
}
//获取 纯化水设备详情操作记录
export const getWaterPreparationDetail = (params, config) => {
    return axios.get(`/production/waterPreparation/info/${params}`, config)
}
//修改 纯化水设备操作记录
export const updateWaterPreparationDetail = (params, config) => {
    return axios.put(`/production/waterPreparation/update`, qs.stringify(params), config).then(res => res)
}
//检查 纯化水设备操作记录
export const checkWaterPreparationList = (params, config) => {
    return axios.put(`/production/waterPreparation/check`, qs.stringify(params), config).then(res => res)
}
